import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';

import '../styles/app.scss';

import { useResourcesTeam } from '../hooks/getResourceTeam';

const Resources = ({ data }) => {
    const { wordpressPage: post } = data;
    const resourcesList = useResourcesTeam();
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle="Parent and Caregiver Support Group" />
			
            <section className="page-section">
                <div className="container blogwrapper reswrap smallestwdt">
				{/* <h2 className="bluetxt soonplaceholder">COMING SOON</h2> */}
                    <img src="https://werockthespectrumracine.wrtsfranchise.com/wp-content/uploads/2024/03/IMG_4259.jpg" alt="img" />
                    <p className='textAlignCenter'>We are so happy to offer a monthly parent and caregiver support group held in the gym. This group is held once a month on Thursday from 6-8pm. Please call or visit our social media sites to know the exact date each month (typically the first Thursday but depending on our monthly schedule that is not always the case). At our support group, families share resources and personal experiences. In addition, We Rock brings in various community resources to share with families. This support group is a free offering to the special needs community. We do a discounted rate for Open Play if you bring your child(ren) along. There is also a meal provided for everyone at each support group. Call us to learn more - we do require a headcount for the kiddos each month to ensure we have enough staff on hand.</p>
                    <div className="bloglftwrap">
                        {resourcesList.map(resource => (
                            <div className="bloglft" key={resource.id}>
                                <div className="blogimg">
                                    <Img
                                        fluid={
                                            resource.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="bloxexc">
                                    <Link
                                        to={`/${resource.slug}`}
                                        className="postName"
                                    >
                                        <h2
                                            dangerouslySetInnerHTML={{
                                                __html: resource.title,
                                            }}
                                        />
                                    </Link>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: resource.excerpt,
                                        }}
                                    />
                                    <Link to={`/${resource.slug}`}>
                                        Read More
                                    </Link>
                                </div>
                                <img
                                    className="blogline"
                                    src={LineFull}
                                    alt="linefull"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Resources;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
